
.main-header {
    background-color: #ffffff;
    position: sticky !important;
    top: 0;
    z-index: 1020;
    height: 80px;
    /* -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 82, 204, 0.3);
    -moz-box-shadow: 0px 1px 0px 0px rgba(0, 82, 204, 0.3);
    box-shadow: 0px 1px 0px 0px rgba(0, 82, 204, 0.3); */
}

.main-header .indexx-icon {
    height: 128px;
    width: 128px;
}

.main-header .navbar-brand {
    color: #05b64f !important;
    font-weight: 600;
}

.main-header a.nav-link {
    margin: 0 6px;
    color: #2b2b2b;
    padding: 4px 8px;
    transition: ease-in-out 0.5s;
}

.main-header a.nav-link:focus,
.main-header a.nav-link:hover {
    background: rgba(0, 82, 204, 0.1);
    border-radius: 5px;
    color: #05b64f;
}

.welcome-title {
    color: #05b64f;
    text-align: center;
    margin-top: 40px;
}

.welcome-description {
    color: #808080 !important;
    text-align: center;
    margin-top: 10px !important;
}

.welcome-icon {
    width: 300px;
    height: 300px;
}

/* Card Start */
.instructions {
    margin-top: 3rem;
    margin-bottom: 2rem;
    color: #808080 !important;
}

.instructions span {
    font-weight: 600;
    color: #05b64f;
    font-size: 2.5rem;
    margin-right: 8px;
}

.instructions span:last-child {
    margin-right: 0px;
    margin-left: 8px;
}

.instruction-card {
    display: flex;
    margin-top: 20px;
    border: 1px solid #05b64f !important;
    flex-direction: row;
    height: 85px;
    padding: 4px;
    /* border-bottom: 3px solid rgba(0, 82, 204, 1) !important; */
}

.instruction-card-number {
    width: 35px;
    color: #808080 !important;
    font-size: 32px;
}

.instruction-card-details {
    display: flex;
    align-items: center;
    color: #808080 !important;
}

/* .card-title div{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background:rgba(0, 82, 204, 0.1);
    border-bottom: 1px solid rgba(0, 82, 204, 1);
    display: grid;
    color: rgba(0, 82, 204, 1);
    place-items: center;
} */

.card-text {
    color: #2b2b2b;
}

/* Card end */


.curculating {
    display: grid;
    place-items: center;
    margin-top: 60px;
    margin-bottom: 30px;
}

.curculating h2 {
    margin-bottom: 0;
}

.curculating .supply {
    color: #808080 !important;
}

.curculating .price {
    color: #05b64f !important;
}

.curculating h2 span {
    font-weight: 600;
}

.price-info {
    text-align: center;
    color: #808080;
    font-weight: 400;
}

.price-title {
    font-weight: 400;
}

.amountPrice {
    font-weight: 400;
}

.supply-card {
    border: 0px solid #05b64f;
}

.non-active-supply-card {
    border: 1px solid #84d12a;
}

.active-supply-card {
    border: 2px solid #05b64f;
}

.price-info h2 {
    color: #808080;
    margin-bottom: 0px !important;
}

.price-info h3 {
    color: #808080;
}

.price-info .discount {
    font-size: 18px;
    font-weight: 400;
}

.price-info .active {
    color: #05b64f;
}

.unitPrice {
    font-weight: 400;
}

.progressBar {
    height: 8px;
}

.progress-bar {
    background-color: #05b64f
}

.percentage {
    text-align: right;
    margin-bottom: 0px !important;
    color: #808080;
}

.walletBtn-connect {
    background-color: #05b64f;
    color: #ffffff;
    font-size: larger;
    width: 240px;
    height: 50px;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 4px;
    cursor: pointer;
}

.buy-now-btn {
    background-color: #05b64f;
    color: #ffffff;
    width: 221px;
    text-align: center;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.walletBtn-connect:hover {
    background-color: #056a11;
    color: white;
}

.buy-now-btn:hover {
    background-color: #056a11;
    color: white;
}


.progress-bar {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.form-control {
    color: #05b64f;
    height: 32px;
    border-radius: 50px;
    margin-left: 10px;
    border: 1px solid #05b64f;
    text-align: right;
}

.form-control:focus {
    color: #05b64f;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

.guide-lines {
    font-size: 10px;
    color: #808080;
    width: 20%;
    margin: auto;
}

.customCard {
    background: #05b64f;
}

.card-header-color {
    text-align: center;
    font-weight: 400;
}

.card-header-inactive {
    color: #808080;
}

.card-header-active {
    color: #ffffff;
}

.Toastify__progress-bar--success {
    background: #05b64f !important;
}

.informationCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.link {
    text-decoration: none;
    padding: 10px;
    color: #05b64f;
}

.instructionsHeading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.viewVideo {
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: #05b64f;
    color: white;
    border-radius: 12px;
    border-color: #05b64f;
    margin-left: 20px;
    padding: 5px 20px;
    font-size: 16px;
}

.viewVideo:hover {
    background-color: #056a11;
    border-color: #056a11;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modalClass {
    text-align: center;
    margin-top: 100px;
}

.modal-dialog {
    max-width: 70vw !important;
}

.modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header {
    font-size: 20px;
    color: #008038;
}

.warningBar {
    padding: 10px;
    background: #fffbe6;
    border-radius: 10px;
    align-items: flex-start;
    border: 1px solid #ffe58f;
    font-size: 14px;
    width: fit-content;
}

.warningBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.site-footer {
    background-color: #c7c9cd;
    padding: 45px 0 20px;
    font-size: 16px;
    line-height: 20px;
    color: #848080fd;
}

.site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5
}

.site-footer hr.small {
    margin: 20px 0
}

.site-footer h6 {
    color: #696969fd;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px
}

.site-footer a {
    color: #737373;
}

.site-footer a:hover {
    color: #0179fa;
    text-decoration: none;
}

.footer-links {
    padding-left: 0;
    list-style: none
}

.footer-links li {
    display: block
}

.footer-links a {
    color: #737373
}

.footer-links a:active, .footer-links a:focus, .footer-links a:hover {
    color: #0179fa;
    text-decoration: none;
}

.footer-links.inline li {
    display: inline-block
}

.site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d
}

.copyright-text {
    margin: 0
}

@media (max-width: 991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) {
    .site-footer {
        padding-bottom: 0
    }

    .site-footer .copyright-text, .site-footer .social-icons {
        text-align: center
    }
}

.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.social-icons li {
    display: inline-block;
    margin-bottom: 4px
}

.social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px
}

.social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.social-icons a:active, .social-icons a:focus, .social-icons a:hover {
    color: #fff;
    background-color: #29aafe
}

.social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px
}

.social-icons a.facebook:hover {
    background-color: #3b5998
}

.social-icons a.twitter:hover {
    background-color: #00aced
}

.social-icons a.linkedin:hover {
    background-color: #007bb6
}

.social-icons a.dribbble:hover {
    background-color: #ea4c89
}

@media (max-width: 767px) {
    .social-icons li.title {
        display: block;
        margin-right: 0;
        font-weight: 600
    }
}


.footerimage {
    margin-left: 30%;
}

.footertext {
    margin-left: 20%;
}

.footercentre {
    text-align: center;
}

.footercentre2 {
    text-align: center;
    /* margin-top: 2%; */
}


div[role='menu'] {
    visibility: hidden;
}

div[role='menu'].visible {
    visibility: visible;
}

#social-wrapper {
    text-align: center;
}

/*Social Media Icons*/
.social-wrapper {
    text-align: center;
}

.social-wrapper ul li {
    display: inline;
    margin: 0 5px;
}

.footer-nav p {
    text-align: center;
}

.footer-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.social-connect-icons {
    cursor: pointer;
    opacity: .6;
    height: 40px;
    width: 40px;
}

.social-connect-icons:hover {
    opacity: 1;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    transform: scale(1.3);
}


