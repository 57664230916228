
.tokens {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.tokenButton {
    padding: 5px;
    margin: 0 10px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #05b64f;
    transition: transform .2s;
}

.tokenButton:hover {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background: #05b64f;
    transform: scale(1.1);
}

.selected {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background: #05b64f;
    transform: scale(1.1);
}

.tokenButton:hover .coinText {
    color: white;
}

.selectedText {
    color: white !important;
}

.buttonIcon {
    height: 25px;
    width: 25px;
    margin-right:5px;
}

.coinText {
    font-size: 11px;
    color: #808080;
    margin-right:5px;
}
